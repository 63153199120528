<template>
	<div>
		<van-nav-bar
		class="nav_bar_color"
		title="客户签名"
		left-text="返回"
		left-arrow
		@click-left="onClickLeft"
		fixed
		z-index="11"
		placeholder 
		:border="false"
		/>
		<img width="100%" :src="url" alt="">
	</div>
</template>
<script>
export default {
	data() {
		return {
			url:''
		}
	},
	mounted(){
		this.url = this.$route.query.url
	}
}
</script>